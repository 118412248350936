import React from 'react'
import TextCard from '~/components/molecules/TextCard'
import Image from '~/components/atoms/Image'
import FadeInWhenVisible from '~/components/FadeInWhenVisible'

export default function TextImageGrid({key, index, ...props}) {

  const section = props.textImageGrid

  return (
    <section id={section.fieldGroupName + index} className={`py-20 md:py-36 bg-sand relative`}>
      <div className="container">
        <div className="grid grid-cols-12 gap-y-12 md:gap-y-0 relative grid-flow-row-dense">
          <div className={`col-span-12 md:col-span-6 grid grid-cols-12 gap-y-12 items-start grid-flow-row-dense md:mt-20 relative`}>
            <TextCard className="text-left col-span-10 col-start-3" {...section?.textCard} />
            {section?.images && (
              <FadeInWhenVisible className="col-span-8 md:col-span-10 ">
                <div className="aspect-w-8 aspect-h-7">
                  <Image data={section?.images[0]?.image} className="!absolute w-full h-full"  />
                </div>
              </FadeInWhenVisible>
            )}
          </div>
          <div className={`col-span-12 md:col-span-6 grid grid-cols-12 grid-flow-row-dense gap-y-12 md:gap-y-24`}>
            {section?.images && (
              <FadeInWhenVisible className="col-span-8 col-start-5 md:col-span-10 md:col-start-3">
                <div className="aspect-w-7 aspect-h-8">
                  <Image  data={section?.images[1]?.image} className="!absolute w-full h-full"  />
                </div>
              </FadeInWhenVisible>
            )}
            {section?.images && (
              <FadeInWhenVisible className="col-span-8 md:col-span-10 ">
                <div className="aspect-w-1 aspect-h-1">
                  <Image  data={section?.images[2]?.image} className="!absolute w-full h-full"  />
                </div>
              </FadeInWhenVisible>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}
